import { combineReducers } from 'redux';

// Import other reducers
import tShirt from './tShirt';
import setting from './setting'
import openai from './openai'
import huggingFace from './huggingFace'
// Combine all reducers
const rootReducer = combineReducers({
    setting,
  openai,
  huggingFace,
  tShirt,
  //add store difirent;
});

export default rootReducer;
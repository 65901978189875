import { createSlice } from '@reduxjs/toolkit'
const initialState = {
  textToImage: [],
  chatGPT: [],
}
const openai = createSlice({
  name: 'openai',
  initialState,
  reducers: {
    UPDATE: (state, action) => {
      for (let key in action.payload) {
        state[key].unshift(action.payload[key])
        // if (key === 'textGenerate') {
        //   state[key].push(action.payload[key])
        //   break
        // } else {
        //   state[key].unshift(action.payload[key])
        //   break
        // }
      }
      return state
    },
  },
})

export const { UPDATE } = openai.actions
export default openai.reducer

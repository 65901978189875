import { createSlice } from '@reduxjs/toolkit'
const initialState = {
  textToImage: [],
  textToNaruto: [],
  textToPokemon: [],
  textGenerate: [],
}
const huggingFace = createSlice({
  name: 'hugging-face',
  initialState,
  reducers: {
    UPDATE: (state, action) => {
      for (let key in action.payload) {
        if (key === 'textGenerate') {
          state[key].push(action.payload[key])
          break
        } else {
          state[key].unshift(action.payload[key])
          break
        }
      }
      return state
    },
  },
})

export const { UPDATE } = huggingFace.actions
export default huggingFace.reducer

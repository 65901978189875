import { createSlice } from '@reduxjs/toolkit'
const initialState = {
  theme: 'light',
  lang: 'en',
}
const setting = createSlice({
  name: 'setting',
  initialState,
  reducers: {
    UPDATE_THEME: (state, action) => {
      state.theme = action.payload
    },
    UPDATE_LANGUAGE: (state, action) => {
      state.lang = action.payload
    },
  },
})

export const { UPDATE_THEME, UPDATE_LANGUAGE } = setting.actions
export default setting.reducer

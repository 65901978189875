import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  colors: ['#ccc', '#EFBD4E', '#80C670', '#726DE8', '#EF674E', '#353934'],
  decals: ['Ghost', 'Salad', 'Girl_AI', 'Monkey', 'Cute_cat', 'Mountain_low_poly', 'Chicken', 'Halloween', 'House_picture'],
  color: '#EFBD4E',
  decal: 'Girl_AI',
  current: null,
  checkDecal: false,
}

const tShirt = createSlice({
  name: 't-shirts',
  initialState,
  reducers: {
    changeColor: (state, action) => {
      state.color = action.payload
    },
    changeDecal: (state, action) => {
      state.decal = action.payload
    },
    checkDecal: (state, action) => {
      state.checkDecal = action.payload
    },
    checkCurrent: (state, action) => {
      state.current = action.payload
    },
  },
})

export const { changeColor, changeDecal, checkDecal, checkCurrent } = tShirt.actions
export default tShirt.reducer
